/* *************************************************
 zero_reports.js
 ***************************************************/
import * as setup from 'src/js/datatables_setup';

$(function() {
  let zeroReportsTable = null;

  zeroReportsTable = $('#zero-report-table').DataTable({
    iDisplayLength: 25,
    bProcessing: true,
    sDom: 'ltipr',
    oLanguage: {
      sProcessing: setup.tableStartProcessing(),
      sEmptyTable: "No data available in table",
      sInfo: "Showing _START_ to _END_ of found entries"
    },
    fnPreDrawCallback: function () {
      var show_accounts = $('#zero-report-table').hasClass('show-accounts');
      $('#zero-report-table').DataTable().column(0).visible(show_accounts);
      setup.tableStartProcessing();
    },
    fnDrawCallback: function() {
      setup.tableStopProcessing();
    },
    bServerSide: true,
    sAjaxSource: $('#zero-report-table').data('source'),
    sPaginationType: "simple",
    aaSorting: [
      [9, "desc"]
    ]
  });

  // Move to the first page after user changes par page option
  $('#zero-report-table').on('length.dt', function(e) {
    zeroReportsTable.page(0);
  })

  $('.dropdown-menu-file-listing').on('click', function(e) {
    e.stopPropagation();
  });

  $('#search-inp').on('keydown', function(e){
    if (e.which == 13) {
      zeroReportsTable.search($(this).val()).draw();
    }
  });

  $('#account_name').on('change', function(){
    zeroReportsTable.columns(0).search($(this).val()).draw();
  });

  $('.search-pmp-select').on('change', function() {
    zeroReportsTable.columns(1).search($(this).children("option:selected").val()).draw();
  });

  $('#submission_begin_date, #submission_end_date').datepicker({
    autoclose: true
  });

  $('#submission_begin_date').change(function() {
    zeroReportsTable.columns(7).search(this.value).draw();
  });

  $('#submission_end_date').change(function() {
    zeroReportsTable.columns(8).search(this.value).draw();
  });
});
