$(function(){
  let pmpSelector = $('#pmp-selector');
  pmpSelector.on('change', function(e){
    const relativePath = encodeURI(pmpSelector.children("option:selected").val());
    window.location = relativePath;
  });
  
  let pmpParam = $('#pmp-selector').data('param');
  show_validations(pmpParam);

  $('#validation-btn').click(function(){
    show_validations(pmpParam);
  });
  $('#misc-btn').click(function(){
    show_misc(pmpParam);
  });
  $('#validation-code-btn').click(function(){
    show_validations_code(pmpParam);
  });
  $('#ucf-fields-btn').click(function(){
    show_ucf_fields(pmpParam);
  });

  $(document).on('change', "input[name='dispensation_destination_validation[manual_pattern_type]']", function () {
    manual_pattern_type_change($(this).val());
  });
  $(document).on('change', "input[name$='][populated_type]']", function () {
    populated_type_change($(this));
  });


  $(document).on('change', '#dispensation_destination_validation_range_type', function () {
    if($(this).val().match(/date/)){
      $('#dispensation_destination_validation_range_low').attr('placeholder', 'Enter date (e.g. YYYY/MM/DD)...');
      $('#dispensation_destination_validation_range_high').attr('placeholder','Enter date (e.g. YYYY/MM/DD)...');
    }else if($(this).val().match(/int/)){
      $('#dispensation_destination_validation_range_low').attr('placeholder', 'Enter integer (e.g. 1)...');
      $('#dispensation_destination_validation_range_high').attr('placeholder','Enter integer (e.g. 2)...');
    }else if($(this).val().match(/dec/)){
      $('#dispensation_destination_validation_range_low').attr('placeholder', "Enter decimal (e.g. 1.0)...");
      $('#dispensation_destination_validation_range_high').attr('placeholder','Enter decimal (e.g. 2.0)...');
    }
  });
});

function set_active_tab(tab_name) {
  $('#misc-btn').removeClass('active');
  $('#validation-btn').removeClass('active');
  $('#validation-code-btn').removeClass('active');
  $('#ucf-fields-btn').removeClass('active');
  $(`#${tab_name}`).addClass('active')
}

function enable_toltips() {
  $('[data-toggle="tooltip"]').tooltip();
}

function initialize_cross_reference_checkboxes() {
  $('#prescriber-cross-reference-switch')
    .bootstrapSwitch();
  $('#dispensary-cross-reference-switch')
    .bootstrapSwitch();
  $('#prescriber-cross-reference-severity-switch').bootstrapSwitch('onText', 'Error')
    .bootstrapSwitch('offText', 'Warning')
    .bootstrapSwitch('onColor', 'danger')
    .bootstrapSwitch('offColor', 'warning');
  $('#dispensary-cross-reference-severity-switch').bootstrapSwitch('onText', 'Error')
    .bootstrapSwitch('offText', 'Warning')
    .bootstrapSwitch('onColor', 'danger')
    .bootstrapSwitch('offColor', 'warning');
}

function manual_pattern_type_change(radioValue) {
  let hiddenInput = $('#hidden_manual_pattern');
  let input = $('#manual_pattern');
  let wasInputEnabled = input.attr('disabled') !== 'disabled';

  if (radioValue === 'custom') {
    hiddenInput.attr('disabled', true);
    hiddenInput.val('');
    input.attr('disabled', false);
    if (input.data('recent-value') !== undefined) {
      input.val(input.data('recent-value'));
    } else {
      input.val('');
    }
  } else {
    hiddenInput.attr('disabled', false);
    hiddenInput.val(radioValue);
    if (wasInputEnabled) {
     input.data('recent-value', input.val());
    }
    input.val(radioValue);
    input.attr('disabled', true);
  }
}
function populated_type_change(radio) {
  let radioValue = radio.val();
  let input = radio.parents('.nested-fields').find('.populated_with');

  if (radioValue === 'present') {
    input.attr('disabled', true)
    input.addClass('d-none')
  } else if (radioValue === 'populated_with'){
    input.attr('disabled', false)
    input.removeClass('d-none')
  }
}

function show_file_configuration(pmp_id) {
  if (typeof pmp_id != 'undefined') {
    $("#tab-body-place-holder").hide();
    $("#loading-section").show();
    $.ajax({
      type: 'get',
      async: false,
      dataType: 'html',
      url: 'pmps/file_configurations/configurations',
      data: 'pmp_id=' + pmp_id,
      success: function (data) {
        file_configuration_load(data);
      }
    });
  }
}

function show_misc(pmp_id) {
  if (typeof pmp_id != 'undefined') {
    $("#tab-body-place-holder").hide();
    $("#loading-section").show();
    $.ajax({
      type: 'get',
      async: false,
      dataType: 'html',
      url: 'pmps/miscellaneous/settings',
      data: 'pmp_id=' + pmp_id,
      success: function (data) {
        misc_load(data);
      }
    });
  }
}

function show_validations(pmp_id) {
  if (typeof pmp_id != 'undefined') {
    $("#tab-body-place-holder").hide();
    $("#loading-section").show();
    $.ajax({
      type: 'get',
      async: false,
      dataType: 'html',
      url: 'pmps/validation_rules/validations',
      data: 'pmp_id=' + pmp_id,
      success: function (data) {
        validation_load(data);
      }
    });
  }
}

function show_validations_code(pmp_id) {
  if (typeof pmp_id != 'undefined') {
    $("#tab-body-place-holder").hide();
    $("#loading-section").show();
    $.ajax({
      type: 'get',
      async: false,
      dataType: 'html',
      url: 'pmps/validation_rules/show_validation_code',
      data: 'pmp_id=' + pmp_id,
      success: function (data) {
        validation_code(data);
      }
    });
  }
}

function show_ucf_fields(pmp_id) {
  if (typeof pmp_id != 'undefined') {
    $("#tab-body-place-holder").hide();
    $("#loading-section").show();
    $.ajax({
      type: 'get',
      async: false,
      dataType: 'html',
      url: 'pmps/ucf_fields/show_fields',
      data: 'pmp_id=' + pmp_id,
      success: function (data) {
        ucf_fields(data);
        override_form_submit(ucf_fields);
      }
    });
  }
}

function show_alert(request) {
  let msgSuccess = request.getResponseHeader('X-Message-Success');
  if (msgSuccess) {
    $('#alert-text').text(msgSuccess);
    $('#page-alert').removeClass("alert-danger");
    $('#page-alert').addClass("alert-success");
    $('#page-alert').show("fast");
  }
  let msgError = request.getResponseHeader('X-Message-Error');
  if (msgError) {
    $('#alert-text').text(msgError);
    $('#page-alert').removeClass("alert-success");
    $('#page-alert').addClass("alert-danger");
    $('#page-alert').show("fast");
  }
}

function validation_code(data) {
  $('#page-alert').hide();
  $("#loading-section").hide();
  $("#tab-body-place-holder").show();
  $('#tab-body-place-holder').html(data);

  set_active_tab('validation-code-btn');
  enable_toltips()
}

function validation_load(data) {
  $('#page-alert').hide();
  $("#loading-section").hide();
  $("#tab-body-place-holder").show();
  $('#tab-body-place-holder').html(data);

  set_active_tab('validation-btn');
  enable_toltips();
  initialize_cross_reference_checkboxes();
}

function override_form_submit(load_func) {
  $('.form-submit').click(function () {
    $("#tab-body-place-holder").hide();
    $("#loading-section").show();
    let form = $(this).closest('form');
    let post = $(this).attr("name") + "=" + $(this).text();
    let valuesToSubmit = form.serialize() + "&" + post;
    $.ajax({
      type: 'put',
      async: false,
      dataType: 'html',
      url: form.attr('action'),
      data: valuesToSubmit,
      success: function (data, textStatus, request) {
        load_func(data);
        show_alert(request);
        window.scrollTo(0, 0);
      }
    });
    return false;
  });
}


function ucf_fields(data) {
  $('#page-alert').hide();
  $("#loading-section").hide();
  $("#tab-body-place-holder").show();
  $('#tab-body-place-holder').html(data);

  set_active_tab('ucf-fields-btn');
  enable_toltips()

  override_form_submit(ucf_fields);
  $(document).trigger('ucf-fields-ready');
}

function misc_load(data) {
  $('#page-alert').hide();
  $("#loading-section").hide();
  $("#tab-body-place-holder").show();
  $('#tab-body-place-holder').html(data);

  set_active_tab('misc-btn');
  enable_toltips()

  if ($("#window-control-group").find(".inline-help").length > 0) {
    $("#window-control-group").addClass("error");
  }
  if ($("#elapsed-control-group").find(".inline-help").length > 0) {
    $("#elapsed-control-group").addClass("error");
  }

  override_form_submit(misc_load);
  $(document).trigger('miscellaneous-ready');
}
