import * as setup from 'src/js/datatables_setup';

$(function () {
  $('#account-table').dataTable({
    bProcessing: true,
    oLanguage: {
      sProcessing: setup.tableStartProcessing(),
      sEmptyTable: "No data available in table"
    },
    fnDrawCallback: function() {
      setup.tableStopProcessing();
    },
    bServerSide: true,
    sAjaxSource: $('#account-table').data('source'),
    sPaginationType: "simple_numbers",
    bStateSave: true,
    aoColumnDefs: [
      {
        "bSortable": false,
        "aTargets": [2, 3, 4, 5]
      },
      {
        "sWidth": "12%",
        "aTargets": [4]
      }
    ],
    aaSorting: [
      [0, "asc"]
    ]
  });

  $('#account-links-table').dataTable({
    bProcessing: true,
    oLanguage: {
      sProcessing: setup.tableStartProcessing(),
      sEmptyTable: "No data available in table"
    },
    bServerSide: true,
    sAjaxSource: $('#account-links-table').data('source'),
    sPaginationType: "simple_numbers",
    bStateSave: true,
    aoColumnDefs: [
      {
        "bSortable": false,
        "aTargets": [1, 2, 3]
      }
    ],
    aaSorting: [
      [4, "asc"]
    ],
    fnDrawCallback: function (oSettings) {
      setup.tableStopProcessing();
      $(document).ready(function () {
        $(".btn-approve").on("click", function (e) {
          e.preventDefault();
          var btn = $(this);
          $.ajax({
            type: "put",
            async: false,
            dataType: "json",
            url: btn.attr("href"),
            success: function (data) {
              var success;
              success = show_alert(data);
              if (success) {
                return toggle_button(btn);
              }
            },
            error: function () {
              return show_alert(null, "Error updating approval on server. Contact support for help.");
            }
          });
          return false;
        });
      });
    }
  });
});

function show_alert(data, errorMsg) {
  var msgError = null;
  if (data != null) {
    if (data.result == "error") {
      msgError = data.error;
    }
  } else if (errorMsg != null) {
    msgError = errorMsg;
  }
  if (msgError) {
    $('#alert-text').text(msgError);
    $('#page-alert').removeClass("alert-success");
    $('#page-alert').addClass("alert-danger");
    $('#page-alert').show("fast");
    window.scrollTo(0, 0)
    return false;
  }
  return true;
}

function toggle_button(btn) {
  if (btn.hasClass("approve-btn")) {
    btn.text('Reject');
    btn.removeClass('approve-btn');
    btn.addClass('reject-btn');
    btn.removeClass('btn-success');
    btn.addClass('btn-danger');
    btn.attr('href', btn.attr('href').replace('approve', 'unapprove'));
  } else if (btn.hasClass("reject-btn")) {
    btn.text('Approve');
    btn.removeClass('reject-btn');
    btn.addClass('approve-btn');
    btn.removeClass('btn-danger');
    btn.addClass('btn-success');
    btn.attr('href', btn.attr('href').replace('unapprove', 'approve'));
  }
}