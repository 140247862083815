hide_dispenser_type = ->
  $('.account_dispenser_subtype').slideUp()

filter_dispenser_subtype = (prefix) ->
  arr = $('.account_dispenser_subtype select option').each (i, e) ->
    value = $(e).attr('value')
    if value.indexOf(prefix) == 0 || value == ''
      $(e).show().prop('disabled', false)
    else
      $(e).hide().prop('disabled', true)
  $('.account_dispenser_subtype').slideDown()

dispenser_type_onchange = (el) ->
  switch $(el).val()
    when 'pharmacy' then filter_dispenser_subtype('ph_')
    when 'dispensing_practitioner' then filter_dispenser_subtype('dp_')
    else hide_dispenser_type()



$(document).ready ->
  dispenser_type_onchange('#account_dispenser_type')

  $('#account_dispenser_type').change ->
    $('.account_dispenser_subtype select').val('')
    dispenser_type_onchange(this)
