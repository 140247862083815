require('../src/scss/application.scss');
import "core-js/stable";
import "regenerator-runtime/runtime";
import $ from 'jquery';
import {} from 'jquery-ujs';
import 'bootstrap';
import dd from 'datatables.net';
import dt from 'datatables.net-bs4';
import 'datatables.net-responsive-bs4';
import 'date-time-format-timezone';
dd(window, $);
dt(window, $);
import 'cocoon-js';
import 'bootstrap-datepicker';
import 'bootstrap-switch';
import 'bootstrap-3-typeahead'
import 'data-confirm-modal'
import '../src/js/bootstrap_file_input';
import '../src/js/hic_four_codes';
import '../src/js/data_retention';
import '../src/js/accounts/pmps';
import '../src/js/pmps/admin';
import '../src/js/pmps/validations';
import '../src/js/asap_file_import';
import '../src/js/dea_numbers';
import '../src/js/custom_checkbox';
import '../src/js/dispenser_subtypes';
import '../src/js/help';
import '../src/js/error_correction';
import '../src/js/file_listing';
import '../src/js/zero_reports';
import '../src/js/zero_report_profiles';
import '../src/js/data_submitters_table';
import '../src/js/data_submitters_table_admin';
import '../src/js/pmp_settings';
import '../src/js/accounts';
import '../src/js/pmp_user';
import '../src/js/pmp';

global.$ = jQuery;

$(function() {  
  
  window.FileListing = window.FileListing || {};
  let hashPass = window.location.hash.replace("#","");
  if(hashPass.length > 0){
    $('#user_email').val(hash);
  }

  if ($('#editUser').data('condition') === "true") {
    $('.navbar').find('.active').removeClass('active');
  }

  let hash = window.location.hash.replace("#","");
  
  if(hash.length > 0){
    $('#username').val(hash);
    $('#password').focus();
  }

  $('[data-toggle="tooltip"]').tooltip();
});

var Application = (function () {
  var ajax_connection_count = 0;
  var animation_count = 0;
  $(document).ajaxSend(function (e, xhr, options) {
    ajax_connection_count = ajax_connection_count + 1;
  });
  $(document).ajaxComplete(function (e, xhr, options) {
    ajax_connection_count = ajax_connection_count - 1;
    if (ajax_connection_count < 0) { // just in case
      ajax_connection_count = 0;
    }
  });
  $(document).on('show hide close slide', function (event) {
    // console.log(event.type, event.target);
    if (event.type === 'hide') {
      if (!$(event.target).is(":visible")) {
        animation_count = animation_count - 1;
      }
    }
    animation_count = animation_count + 1;
  });
  $(document).on('shown hidden closed slid', function (event) {
    // console.log(event.type, event.target);
    animation_count = animation_count - 1;
    if (animation_count < 0) {
      animation_count = 0;
    }
  });
  function prevent_loading_me_in_ajax() {
    // If we load a partial that is rendering a full template, we will have more than one title tag...
    if ($("title").length > 1) {
      location.reload();
    }
  }

  function animation_queue_count() {
    var queue_count = 0;
    $("*").each(function () {
      queue_count = queue_count + $(this).queue("fx").length;
    });
    return queue_count;
  }

  function animating_elements_count() {
    return $(":animated").length;
  }

  function am_I_resting() {
    // He's not resting, he's pining for the fjords!
    var queue_count = animation_queue_count();
    if ((queue_count > 0) || (ajax_connection_count > 0) || (animation_count > 0) || (animating_elements_count() > 0)) {
      // console.log(false);
      return false;
    } else {
      // console.log(true);
      return true;
    }
  }

  function debug_resting() {
    console.log("queue count:", animation_queue_count(),
      "animation count:", animation_count,
      "ajax connection count:", ajax_connection_count);
  }

  return {
    prevent_loading_me_in_ajax: prevent_loading_me_in_ajax,
    am_I_resting: am_I_resting,
    debug: debug_resting
  };
}());


$(window).on('load', function () {
  //Remove widths from datatables with ajax pagination. Allow responsive.
  //Do not set width to auto for non ajax paginated datatables. (those with the datatable class all lowercase)
  var dt = $('.dataTable')
  if (!dt.hasClass('datatable')) {
    dt.width('auto');
  }
  dt.css('margin', 'auto');
});

/**
 * INITIALIZE PLUGINS HERE
 */
$(document).ready(function () {
  // For making the date pickers pretty
  $('.datepicker').datepicker({
    autoclose: true,
    assumeNearbyYear: true,
    startDate: '01-01-1900'
  });

  //Init bootstrap tooltips and popovers
  $("[rel=popover]").popover();
  $(".tooltip").tooltip();
  $("[rel=tooltip]").tooltip();

  //Make navbars dropdons active when child is active.
  var activeNav = $('.dropdown-menu li.active');
  if (activeNav.length > 0) {
    var dropdown = activeNav.parents('.dropdown:eq(0)');
    dropdown.addClass('active');
  }

  //Tooltips plugin
  $('[data-toggle=tipsy],[rel=tipsy]').each(function () {
    var gravity = 'n';
    if ($(this).attr('data-direction')) {
      gravity = $(this).attr('data-direction');
    }

    var delayOut = 0;
    if ($(this).attr('data-delay-out')) {
      delayOut = parseInt($(this).attr('data-delay-out'));
    }

    var delayIn = 0;
    if ($(this).attr('data-delay-in')) {
      delayIn = parseInt($(this).attr('data-delay-in'));
    }

    $(this).tooltip({fade: true, gravity: gravity, delayOut: delayOut, delayIn: delayIn});
  });
  var hideAlertBox = $('.alert-hide');
  if (hideAlertBox.length > 0 && !hideAlertBox.hasClass('alert-error')) {
    //fade out alerts with class alert-hide
    window.setTimeout("$('.alert-hide').fadeOut('slow');", 6000);
  }

});

$(document).ajaxError(function (e, xhr, settings) {
  if (xhr.status == 401) {
    location.reload();
  }
});
