import * as setup from 'src/js/datatables_setup';

$(function(){
  $('#error_correction_table').dataTable({
    bProcessing: true,
    oLanguage: {
      sProcessing: setup.tableStartProcessing(),
      sEmptyTable: "No data available in table"
    },
    fnDrawCallback: setup.tableStopProcessing(),
    bServerSide: true,
    sAjaxSource: $('#error_correction_table').data('source'),
    sPaginationType: "simple_numbers",
    aoColumnDefs: [ { "bSortable": false,  "aTargets": [ 8 ] } ],
    aLengthMenu: [[10, 25, 50, 100], [10, 25, 50, 100]],
    searchDelay: 2000
  });

  $.fn.overrideNodeMethod = function(methodName, action) {
    var originalVal = $.fn[methodName];
    var thisNode = this;
    $.fn[methodName] = function() {
      if (this[0]==thisNode[0]) {
        return action.apply(this, arguments);
      } else {
        return originalVal.apply(this, arguments);
      }
    };
  };

  // The typeahead-bootstrap lib uses position() function to calculate coordinates of suggestions ul element.
  // This does not work properly when input is inside table's td element.
  // Using offset() function istead fixes this problem
  $('form#edit_error_form input.ndc-search').overrideNodeMethod('position', $.fn.offset);
  $('form#edit_error_form input.ndc-search').typeahead({
    minLength: 4,
    delay: 400,
    items: 'all',
    updater: function(item) {
      return item.split(' - ')[0];
    },
    source: function(query, process) {
      $.ajax({
        url: '/ndc_suggestions',
        data: {term: query},
        dataType: 'json'
      }).done(function(response) {
        process(response);
      })
    }
  })
});

