import map from 'src/js/jquery.usmap';
import {Spinner} from 'spin.js';
import 'bootstrap/dist/js/bootstrap';

var isPmpSettingsChanged = false;

$(function () {
  $('.pmps-cancel-modal button.submit').click(function() {
    let stateModal = $('#state-remove-confirm');
    stateModal.modal('hide');
    var stateCode = stateModal.attr('data-state');
    var stateTableData = $("#state-approval-table").find('tr[data-state=' + stateCode + ']');
    toggleStateWebRequest(stateTableData, stateCode);
    $(stateTableData).find("input:checkbox").prop("checked", false)
  });

  var acctID = $('#account-id').attr('data-id');
  if (acctID != undefined) {
    bindEvent(window, "state-approval", function (e) {
      var stateCode = e.detail.additional_info;
      var approvalTableData = $("#state-approval-table").find("tr[data-state='" + stateCode + "']").find('.state-approval');
      var textStyle;
      var statueMessage;
      if (e.detail.status_type == 'success') {
        statueMessage = 'Approved';
        textStyle = 'text-success';
      } else if (e.detail.status_type == 'error') {
        statueMessage = 'Rejected';
        textStyle = 'text-error';
      }
      approvalTableData.find('strong').text(statueMessage).addClass(textStyle);
      $('#us-approval-map').usmap('trigger', stateCode, 'click', statueMessage);
    });

    /**
     * Handle State select box toggles. Send out a request to toggle approval
     */
    $('.state-checkbox').on('click', function (e) {
      var stateTableData = $(this).closest('tr');
      var stateCode = stateTableData.attr('data-state');
      //The icon check icon is changed before this event is fired so this check seems backwards but actually right.
      if (!$(this).is(':checked')) { //Signals disapproval. Prompt confirmation.
        e.preventDefault();
        var stateModal = $('#state-remove-confirm');
        stateModal.modal('show');
        stateModal.attr('data-state', stateCode);
      } else {
        toggleStateWebRequest(stateTableData, stateCode);
      }
    });

    /**
     * US Map rendering and and events logic.
     */
    var pmpAccountsUrl = "/accounts/" + acctID + "/pmps" + '?' + window.crypto.getRandomValues();
    $.get(pmpAccountsUrl, null, null, 'json')
      .done(function (data) {
        initMap(data);
      });
  }

});

function bindEvent(el, eventName, eventHandler) {
  if (el.addEventListener) {
    el.addEventListener(eventName, eventHandler, false);
  } else if (el.attachEvent) {
    el.attachEvent('on' + eventName, eventHandler);
  }
}

function toggleStateWebRequest(stateTableData, stateCode) {
  let statusField = stateTableData.find('.state-approval');
  var spinner = new Spinner({radius: 4, width: 2}).spin();
  statusField.prepend(spinner.el);
  $.get("/pmp_account_links/request_approval_toggle", { state_cd: stateCode })
    .done(function (data) {
      let message = $.parseJSON(data);
      handleApprovalResponse(stateCode, message, statusField, spinner);
    })
    .fail(function () {
      handleApprovalResponse(stateCode, {error: 'Unable to make request. Contact support.'}, statusField, spinner);
    });
}

function handleApprovalResponse(stateCode, message, statusField, spinner) {
  if (message.success != undefined) {
    updateStateRequestStatus(stateCode, message, statusField, spinner);
  } else {
    statusField.text(message.error).addClass('text-error');
    $('#us-approval-map').usmap('trigger', stateCode, 'click', "NA");
    stopStateApproveSpinner(spinner);
  }
}

function stopStateApproveSpinner(spinner) {
  if (spinner != undefined && spinner != null) {
    spinner.stop();
  }
}

function updateStateRequestStatus(stateCode, message, statusField, spinner) {
  setTimeout(function () {
    if (spinner != undefined && spinner != null) {
      spinner.stop();
    }
    statusField.html(statusFieldRender(message.success));
    $('#us-approval-map').usmap('trigger', stateCode, 'click', message.success);
    if (!isPmpSettingsChanged) {
      $("#auto-saved-text").fadeIn();
      isPmpSettingsChanged = true;
    }
  }, 500);
}

function statusFieldRender(msg){
  return "<span class=text-" +
    (msg === 'Approved' ? 'success' : 'warning') +
    "><strong>" +
    msg +
    "</strong></span>";
}

/**
 * US Map rendering and and events logic.
 */

function buildStateStyles(pmpStatuses) {
  var styles = {};
  $.each(pmpStatuses, function (index, pmpStatus) {
    if (pmpStatus.approved == true) {
      styles[pmpStatus.state_cd] = {fill: '#468847'};
    } else if (pmpStatus.approved == false) {
      styles[pmpStatus.state_cd] = {fill: '#c09853'};
    } else {
      styles[pmpStatus.state_cd] = {fill: 'white'};
    }
  });

  return styles;
}

function fillShape(data, color) {
  if (typeof data.shape != 'undefined') {
    data.shape.attr('fill', color);
  }
}

function initMap(pmpStatuses) {
  var lastFill = null;
  $('#us-approval-map').usmap({
    stateStyles: {fill: '#EDEDED'},
    stateSpecificStyles: buildStateStyles(pmpStatuses),
    stateHoverStyles: {fill: '#d3d3d3'},
    showLabels: false,
    click: function (event, data) {
      if (typeof(event.originalEvent) === 'string' || event.originalEvent instanceof String) {
        if (event.originalEvent == "Pending") {
           fillShape(data, '#c09853');
        } else if (event.originalEvent == "Approved") {
          fillShape(data, '#468847');
        }
        else {
          fillShape(data, 'white');
        }
      }
    },
    mouseover: function (event, data) {
      $('#hovered-state').text('State Abbreviation: ' + data.name);
      lastFill = data.shape.attr('fill');
      fillShape(data, '#d3d3d3');
      return false;
    },
    mouseout: function (event, data) {
      $('#hovered-state').text('');
      fillShape(data, lastFill);
      event.preventDefault();
    }
  });
}
