import * as setup from 'src/js/datatables_setup';

$(function () {
  let table = $('#file_listing_table');
  let fileListingTable = null;

  fileListingTable = $('#file_listing_table').DataTable({
    bProcessing: true,
    sDom: 'ltipr',
    oLanguage: {
      sProcessing: '',
      sEmptyTable: "No data available in table",
      sInfo: "Showing _START_ to _END_ of found entries"
    },
    fnPreDrawCallback: function () {
      var show_accounts = $('#file_listing_table').hasClass('show-accounts');
      $('#file_listing_table').DataTable().column(0).visible(show_accounts);
      setup.tableStartProcessing();
    },
    fnDrawCallback: function () {
      setup.tableStopProcessing();
      $('[data-toggle="popover"]').popover({
        sanitize: false
      });
      $('[data-tooltip="tooltip"').tooltip();
    },
    bServerSide: true,
    sAjaxSource: table.data('source'),
    dataSrc:"",
    sPaginationType: "simple",
    aaSorting: [
      [6, 'desc']
    ],

    aoColumnDefs: [
      { "bVisible": false, "aTargets": [ 0 ] },
      { "bSortable": false, "aTargets": [7, 8] },
      { "sWidth": "140px", "aTargets": [ 4, 5, 8] },
      { "sWidth": "25%", "aTargets": [1] },
      { "sWidth": "200px", "aTargets": [7] }
    ],

    aoColumns: [
      null, // 0: Account
      null, // 1: File name
      null, // 2: US State
      null, // 3: Record count
      null, // 4: Warning count
      null, // 5: Error count
      null, // 6: Submitted
      { "sClass": "text-center" }, // 7: Status
      null // 8: Status Report
    ],
    aLengthMenu: [
      [10, 25, 50, 100],
      [10, 25, 50, 100]
    ]
  });

  $('.dropdown-menu-file-listing').on('click', function(e) {
    e.stopPropagation();
  });

  // Move to the first page after user changes par page option
  table.on('length.dt', function(e) {
    fileListingTable.page(0);
  })

  $('#search-inp, #file_name').on('keyup', function(){
    fileListingTable.search($(this).val()).draw();
  });

  $('#account_name').on('keyup', function(){
    fileListingTable.columns(0).search($(this).val()).draw();
  });

  $('.search-pmp-select').on('change', function() {
    fileListingTable.columns(2).search($(this).children("option:selected").val()).draw();
  });

  $('#submission_begin_date, #submission_end_date').datepicker({
    autoclose: true
  });

  $('#submission_begin_date').change(function() {
    fileListingTable.columns(6).search(this.value).draw();
  });

  $('#submission_end_date').change(function() {
    fileListingTable.columns(7).search(this.value).draw();
  });

  $('#refresh-table-btn').click(function(){
    if (fileListingTable == null || fileListingTable == undefined) return;
    fileListingTable.draw();
  });


});

$(window).on('load', function () {
  let table_filter = $('#file_listing_table_filter');
  $('#refresh-table-btn').parent().insertBefore(table_filter)

  $("#refresh-table-btn").tooltip({fade: true, gravity: 'e', delayOut: 1});

  var timer;
  $( document ).on( "mouseover", "#refresh-table-btn", function() {

    timer = setTimeout(function() {
      $('#refresh-table-btn').tooltip('hide');
    },2000);
  });

  $( document ).on( "mouseout", "#refresh-table-btn", function() {
    clearTimeout(timer);
  });
});
