$(function () {
  $('dl.help dt.btn-link').on('click', function () {
    var collapseDownIcon = $(this).children('i.icon-chevron-down');
    var collapseUpIcon = $(this).children('i.icon-chevron-up');

    if (collapseDownIcon.length > 0) {
      collapseDownIcon.addClass('icon-chevron-up');
      collapseDownIcon.removeClass('icon-chevron-down');
    } else {
      collapseUpIcon.addClass('icon-chevron-down');
      collapseUpIcon.removeClass('icon-chevron-up');
    }

  });
});