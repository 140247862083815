$(document).on 'miscellaneous-ready', ->
  $('.rollup_checkbox').bootstrapSwitch();
  $('.accept_dea_lookup_failure_checkbox').bootstrapSwitch();
  $('.accept_ncpdp_lookup_failure_checkbox').bootstrapSwitch();
  $('.accept_npi_lookup_failure_checkbox').bootstrapSwitch();
  $('.ca_pha11_custom_validation_checkbox').bootstrapSwitch();
  $('.ca_pha13_custom_validation_checkbox').bootstrapSwitch();
  $('.waiver_dea_support_checkbox').bootstrapSwitch();
  $('.minor_checkbox').bootstrapSwitch('onText', 'Discard')
    .bootstrapSwitch('offText', 'Retain')
    .bootstrapSwitch('onColor', 'danger')
    .bootstrapSwitch('offColor', 'success');
  $('.dea_number_management_checkbox').bootstrapSwitch();
  $('.allow_duplicates_checkbox').bootstrapSwitch();
  $('.display_duplicates_checkbox').bootstrapSwitch();
  $('.hash_based_deduplication_checkbox').bootstrapSwitch();
  $('.v3_deduplication_hash_checkbox').bootstrapSwitch();
  $('.state_licenses_checkbox').bootstrapSwitch();
  $('.fault_tolerance_checkbox').bootstrapSwitch();
  $('[data-toggle="tooltip"]').tooltip();
  $('.drug_schedule_active_checkbox').bootstrapSwitch({
    onText: 'Excluded',
    offText: 'Allowed',
    onColor: 'danger',
    offColor: 'success'
  });

  $('.dea_schedule_active_checkbox').bootstrapSwitch({
    onText: 'Required',
    offText: 'Preferred',
    onColor: 'danger',
    offColor: 'success'
  });

  $(document).on 'ucf-fields-ready', ->
    $('.ucf_field_checkbox').bootstrapSwitch({
      onText: 'On',
      offText: 'Off',
      onColor: 'success',
      offColor: 'danger',
      size: 'mini'
    })

$(document).on 'switchChange.bootstrapSwitch', '.fault_tolerance_checkbox', ->
  fault_sel = '#pmp_fault_tolerance_percentage, #pmp_fault_tolerance_minimum';
  $(fault_sel).prop('disabled', !$(this).prop('checked'));
