$(document).ajaxComplete(function(){
  $('.data-retention-btn').click(function (event) {
    event.preventDefault();
    var results = $("#data-retention-errors");
    results.text('');
    results.show();
    results.removeClass('text-success');
    results.removeClass('text-danger');
    types = ['text/csv', 'application/vnd.ms-excel']
  

    var formData = new FormData();
    formData.append('data_retention_invalid', $('#data-retention-invalid').val());
    formData.append('pmp_id', $('#pmp_pmp_id').val());
    var form = $(this).closest('form');

    $.ajax({
      type: "POST",
      url: form.attr('action'),
      data: formData,
      processData: false,
      contentType: false,
      success: function (data, textStatus, request) {
        if(data.error == undefined) {
          results.addClass('text-success');
          results.text( data.success );
          results.delay(4600).fadeOut('slow');
        }else {
          results.addClass('text-danger');
          results.html(data.error.join("<br>"));
        }
      }
    });
  });
});
