$(document).ready(function() {
  $('form#new_asap_file_import').change(function(){
    var disabled = $(this).find('.disable-upload:input').filter(function(){
      return !$(this).val();
    }).length != 0;
    $(this).find('#upload-modal-btn').attr('disabled', disabled);
  });
  
  $('form#new_asap_file_import').change(function(){
    var fileName = $(this).val();
    $('.custom-file-label').html.find(fileName);

    var file_size = $('#asap_file_import_asap_file')[0].files[0];
    if (file_size !== undefined && file_size !== null) {
      let asapFileMaxSize = document.getElementById("asap-file-max-size")
          .getAttribute('data-asap-file-max-size')

      if (file_size.size > asapFileMaxSize * 1024 * 1024 ) {
        alert(`File too large: ASAP files must be less than ${asapFileMaxSize} MB.`);
        $('#asap_file_import_asap_file').val('');
        $('#file-upload-box input.file-input-name').val('');
      }
    }
  });

  $('#file-upload-btn').click(function() {
    $('#confirmationDialog').modal('toggle');
  });
});
