import * as setup from 'src/js/datatables_setup';

$(function () {
  $('#submitter-users-table-admin').dataTable({
    bProcessing: true,
    oLanguage: {
      sProcessing: setup.tableStartProcessing(),
      sEmptyTable: "No data available in table"
    },
    fnDrawCallback: function() {
      setup.tableStopProcessing();
    },
    bServerSide: true,
    sAjaxSource: $('#submitter-users-table-admin').data('source'),
    sPaginationType: "simple_numbers",
    bStateSave: true,
    aoColumnDefs: [
      {
        "bSortable": false,
        "aTargets": [3, 6, 7]
      },
      {
        "sWidth": "10%",
        "aTargets": [8]
      },
      {
        "sWidth": "120px",
        "aTargets": [5]
      }
    ],
    aaSorting: [
      [8, "asc"]
    ]
  });
});