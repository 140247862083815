$(document).ajaxComplete(function(){
  $('.form-submit-file').click(function (event) {
    event.preventDefault();
    var file = $("#hic-four-code-file")[0].files[0];
    var results = $("#hic-four-code-errors");
    results.text('');
    results.show();
    results.removeClass('text-success');
    results.removeClass('text-danger');
    types = ['text/csv', 'application/vnd.ms-excel']
    
    if (file === undefined || !types.includes(file.type)) {
      results.addClass('text-danger');
      error = file === undefined ? 'You must specify a CSV file' : 'File type must be CSV';
      results.text(error).delay(4600).fadeOut('slow');
      event.preventDefault();
      return  false;
    }

    var formData = new FormData();
    formData.append('file', file);
    formData.append('pmp_id', $('#pmp_pmp_id').val());
    var form = $(this).closest('form');

    $.ajax({
      type: "POST",
      url: form.attr('action'),
      data: formData,
      processData: false,
      contentType: false,
      beforeSend: function() {
        $('.form-submit-file').attr('disabled', true);
      },
      success: function (data, textStatus, request) {
        $('.form-submit-file').attr('disabled', false);
        if(data.error == undefined) {
          results.addClass('text-success');
          results.text( data.success );
          results.delay(4600).fadeOut('slow');
        }else {
          results.addClass('text-danger');
          results.html(data.error.join("<br>"));
        }
      }
    });
  });
});
