import {Spinner} from 'spin.js';

function show_validations_modal(pmp_id, record_type, column) {
  $.ajax({
    type: 'get',
    async: false,
    dataType: 'html',
    url: 'pmps/validation_rules/edit_validation',
    data: 'pmp_id=' + pmp_id + '&model_class=' + record_type + '&attribute_name=' + column,
    success: function (data) {
      $('#modal_div').html(data);
      $('#validations_modal').modal('show');
    }
  });
}

function validateTypes(results, formId) {
  let identity = '';
  let failoverIdentity = '';
  
  if (formId === 'prescriber_identifier_type_form') {
    identity = $('#prescriber_identifier_type').val();
    failoverIdentity = $('#prescriber_identifier_failover_type').val();
  } else if (formId === 'dispensary_identifier_type_form') {
    identity = $('#dispensary_identifier_type').val();
    failoverIdentity = $('#dispensary_identifier_failover_type').val();
  }

  if (identity === failoverIdentity) {
    results.addClass('text-danger');
    results.text( "Identifier Type and Identifier Failover Type can't be the same");
    results.delay(4600).fadeOut('slow');
    return false
  } else {
    return true
  }
}

$(document).ajaxComplete(function(){
  $('.validation-modal').on('click', function() {
    let pmp_id = $(this).data('pmp-id');
    let record_type = $(this).data('rec-type');
    let column = $(this).data('rec-attr');
    $('.modal-backdrop').remove();
    show_validations_modal(pmp_id, record_type, column);
  });

  $(".ddv_toggle_checkbox").bootstrapSwitch({
    'size': 'mini',
    'onSwitchChange': function(event, state){
      $("#" + event.target.id).closest('.form-group').find('.controls').toggleClass('d-none');
    },
  });
  
  $("a.reset_link").bind("ajax:success", function(json, status, xhr) {
    if(status['status'] = 'success'){
      $('div.success_msg').html("<div class='alert alert-success'><button type='button' class='close' data-dismiss='alert'>&times;</button><h5>" + status['msg'] + "</h5></div>");
    }else{
      $('div.errors_msg').html("<div class='alert alert-danger'><button type='button' class='close' data-dismiss='alert'>&times;</button><h5>" + status['msg'] + "</h5></div>");
    }
  });
  
  $('.accordion-toggle').first().click();

  $('.accordion').on('show hide', function (n) {
    $(n.target).siblings('.accordion-heading').find('.accordion-toggle i').toggleClass('fa-chevron-up fa-chevron-down');
    $(n.target).siblings('.accordion-heading').toggleClass('show-border hide-border');
  });

  $('#accordion3').on('show hide', function (n) {
    $(n.target).siblings('.accordion-heading').find('.accordion-toggle i').toggleClass('fa-chevron-up fa-chevron-down')
    $(n.target).siblings('.accordion-heading').toggleClass('show-border hide-border');
  });

  $("#dispensary_identifier_type_form, #prescriber_identifier_type_form").submit(function( event ) {
    let form = $(this);
    let results = form.find(".identifier-type-results");
    results.text('');
    results.show();
    results.removeClass('text-success');
    results.removeClass('text-danger');
    
    if (validateTypes(results, this.id) === false) {
      event.preventDefault();
      return  false;
    }
    
    let spinnerClass = form.find('.spinner');
    const spinner = new Spinner({radius: 4, width: 2}).spin();
    spinnerClass.prepend(spinner.el);
    
    $.post(form.attr('action'), form.serialize(), function( data ) {
      setTimeout(function(){
        spinner.stop();
        spinnerClass.html('');
        if(data.success != undefined) {
          window.location.replace(data.success);
        }else {
          results.addClass('text-danger');
          results.html(data.error.join("<br>"));
        }
        results.delay(4600).fadeOut('slow');
      }, 500);

    });
    
    event.preventDefault();
  });
});
