import * as setup from 'src/js/datatables_setup';

$(function() {
  $("#zero_report_pmp_id").on("change", function(e) {
    var pmpId = this.value;

    if (pmpId) {
      let path = $(this).data('path');
      window.location.replace(`${path}?pmp_id=${pmpId}`);
    }
  })

  $('input[name="zero_report[submit_method]"]').change(function() {
    var path = $(this).data('path');

    window.location.replace(path);
  });
});
